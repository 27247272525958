<template>
    <div>
        <admissions-agent-form
                v-if="!loading && subscriptions.length > 0"
                :original-form-data="formData"
                :current-photo="currentPhoto"
                @submit="attemptSubmit($event)"
        ></admissions-agent-form>

        <md-snackbar
            md-position="center"
            :md-duration="Infinity"
            :md-active.sync="showSnackbar"
            md-persistent
        >
            <span>{{ snackMsg }}</span>
            <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
        </md-snackbar>
        <div class="container" v-show="subscriptions == 0">
            <app-requestabed></app-requestabed>
        </div>

    </div>
</template>

<script lang="javascript">

    import AdmissionsAgentForm from "./Forms/AdmissionsAgentForm";
    import {mapActions, mapGetters} from 'vuex'

    export default{
        // mixins: [validationMixin, customValidationMixin],
        name: 'Contact',
        components: {AdmissionsAgentForm},
        data(){
            return {
                formData: {
                    firstName: null,
                    lastName: null,
                    email: null,
                    // phone: null,
                    photo: null,
                    presetPhoto: null,
                    selectedFile: null,
                },
                loading: false,
                showSnackbar: false,
                snackMsg: '',
                currentPhoto: null
            }
        },
        computed: {
            ...mapGetters({
                models: 'campaigns',
                defaultPaymentMethod: 'defaultPaymentMethod',
                checkCampaignsIsPremium: "checkCampaignsIsPremium",
                subscriptions:  'subscriptions'
            }),
        },

        async mounted(){
            await this.loadData();
        },
        watch: {
            '$route.params.facilityId'(newId, oldId) {
                 this.loadData()
            },

            loading(newVal, oldVal) {
                this.$emit('loading', newVal);
            }
        },

        methods: {
            ...mapActions([
                'createAdmissionsAgent',
                'fetchAdmissionsAgent',
                'submitAdmissionsAgent'
            ]),

            async loadData(){
                this.loading = true;

                const admissionsAgent = await this.fetchAdmissionsAgent(this.$route.params.facilityId);
                if(admissionsAgent){
                    //console.log('ADMISSIONS AGENT', admissionsAgent);
                    this.formData.firstName = admissionsAgent.firstName
                    this.formData.lastName = admissionsAgent.lastName
                    this.formData.email = admissionsAgent.email

                    // if(admissionsAgent.phone){
                    //     let agentPhone = admissionsAgent.phone
                    //     if(agentPhone.length === 12)
                    //         agentPhone = agentPhone.slice(2)
                    //     if(agentPhone.length === 11 && agentPhone[0] === 1)
                    //         agentPhone = agentPhone.slice(2)
                    //     this.formData.phone = agentPhone
                    // }

                    if(admissionsAgent.photo){
                        this.currentPhoto = admissionsAgent.photo;
                    }
                }

                this.loading = false;
            },

            async attemptSubmit(formData){
                try{
                    this.submitted = true;

                    this.loading = true;

                    await this.submitAdmissionsAgent({
                        facilityId: this.$route.params.facilityId,
                        formData
                    })

                    await this.loadData();

                    this.snackMsg = this.snackMsg = "Your updates have been saved."
                    this.showSnackbar = true;
                    this.loading = false;
                }catch(e){
                    this.snackMsg = this.snackMsg = "Something went wrong. Please contact support."
                    this.showSnackbar = true;
                    console.error(e);
                }

            },

        }
    }

</script>

<style lang="scss" scoped>

    .admin .delete {
        height: 34px;
        width: 35px;
        position: absolute;
        right:0;
        top: 0;
    }
    .md-title{
        display: inline-flex;
    }

    .md-app-content .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
    }
    .md-app-content {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding: 0px;
    }

     .md-menu-content.md-select-menu{
        top: 413px!important;
        width: 14.5%!important;
    }
    .md-tabs-navigation, .md-tab{
        margin-bottom: 0px;
        padding: 0px;
    }


    .md-content.md-tabs-content.md-theme-default {
        height: auto;
        padding-bottom: 20px;
    }

     .md-icon:hover{
         cursor: pointer;
     }

    .tooltipemail{
        top: 190px!important;
    }
</style>

<style>
 .tooltipemail{
        top: 180px!important;
    }

   .bulk-admin .md-invalid .md-error{
       margin-left: 0;
   }

    #admissions-modal .admin .md-invalid .md-error{
        left: 0px!important;
    }

</style>