<template>
    <div>
        <div class="md-layout admin details media">
            <md-progress-spinner v-if=loading md-mode="indeterminate" class="spinner" ></md-progress-spinner>
            <div class="md-layout-item md-size-100">
                <div class="md-layout">
                    <div class="md-layout-item" :class="{'md-size-85' : !mobile}" v-if="$route">
                        <md-tabs :md-sync-route="true" md-active-tab="activeTab" @md-changed="tabChange" :class="{'no-box-shadow': $route.name==='listing.admissions-agent'}">

                            <md-tab id="tab-pages" md-label="General" :to="{name: 'general.index'}"
                            >
                                <general v-if="$route.name === 'general.index'" @loading="loading = $event"/>
                            </md-tab>

                            <md-tab id="admissions-tab" md-label="Admissions" :to="{name: 'listing.admissions-agent'}"
                            >
                                <admissions-agent v-if="$route.name === 'listing.admissions-agent'" @loading="loading = $event"/>
                            </md-tab>

                            <md-tab id="contact-tab" md-label="Contact" :to="{name: 'contact.index'}"
                            >
                                <contact v-if="$route.name === 'contact.index'" @loading="loading = $event" />
                            </md-tab>

                            <md-tab id="summary-tab" md-label="Summary" :to="{name: 'listing.summary'}">
                                <description v-if="$route.name === 'listing.summary'" @loading="loading = $event" />
                            </md-tab>

                            <md-tab id="overview-tab" md-label="Overview" :to="{name: 'listing.overview'}">
                                <description v-if="$route.name === 'listing.overview'" @loading="loading = $event" />
                            </md-tab>

                            <md-tab id="media-tab" md-label="Media" :to="{name: 'media.index'}">
                                <media v-if="$route.name === 'media.index'" @loading="loading = $event" />
                            </md-tab>

                        </md-tabs>
                        <app-facilitytooltip v-if="!loading"></app-facilitytooltip>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import General from "./General";
    import AdmissionsAgent from "./AdmissionsAgent";
    import Contact from "./Contact";
    import Description from "./Description";
    import Media from "./Media";
    export default {
        name: "Admin.vue",
        components: {
            General,
            AdmissionsAgent,
            Contact,
            Description,
            Media,
            mobile: false
        },
        created(){
            if( this.windowWidth <= 760 ) {
                this.mobile = true
            }
        },
        data() {
            return {
                activeTab: 'General',
                loading: false,
                mobile:false
            }
        },
        mounted() {
            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })
            if (this.$route.name === 'general.index') {
                this.activeTab = 'General'
            } else if(this.$route.name === 'listing.admissions-agent'){
                this.activeTab = "Admissions"
            } else if (this.$route.name === 'contact.index'){
                this.activeTab = "Contact"
            } else if (this.$route.name === 'listing.summary'){
                this.activeTab = 'Summary';
            }else if (this.$route.name === 'listing.overview'){
                this.activeTab = 'Overview';
            } else if(this.$route.name === 'media.index'){
                this.activeTab = 'Media'
            }
        },
        methods: {
            tabChange(name) {
                //console.log('tab name: ', name);
            }
        }
    }
</script>

<style scoped>
    .md-app-content .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
    }
    .md-app-content {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding: 0px;
    }
    .md-tab {
        padding: 0px;
    }
    .customGrey{
       
        background-color: unset;
      
    }

    .md-tabs-content {
      overflow: visible !important;
      overflow-y: visible !important;
    }

</style>

<style lang="scss">

    .no-box-shadow {
        box-shadow: none !important;
    }
    .md-tabs-content table{
        width: 100%;
    }

    .md-dialog .admin .md-invalid .md-error{
        left: 0px!important;
    }

    .admin {
        .md-tabs-content {
          overflow: visible !important;
        }
    }


</style>