<template>
    <form novalidate @submit.prevent="attemptSubmit" v-show="!loading">
        <md-card>
            <md-card-content>
                <div class="md-layout md-gutter md-size-100">
                    <div class="md-layout-item no-table">

                        <!-- FIRSTNAME -->
                        <div class="form-group" :class="{'md-invalid': showMdInvalidClass($v.formData.firstName, submitted)}">
                            <!--                                                            <div >-->
                            <label for="first-name">First name</label>
                            <input
                                    @input="formChanged=true"
                                    type="text"
                                    name="first-name"
                                    id="first-name"
                                    v-model="formData.firstName"
                                    placeholder="Pseudonym first"
                            />
                            <span class="md-error" v-if="!$v.formData.firstName.required && showMdInvalidClass($v.formData.firstName, submitted)"><md-icon class="error-icon">error</md-icon>First name is required</span>
                        </div>


                        <!-- LAST NAME -->
                        <div class="form-group" :class="{'md-invalid': showMdInvalidClass($v.formData.lastName, submitted)}">
                            <!--                                                            <div>-->
                            <label for="last-name">Last name</label>
                            <input
                                    @input="formChanged=true"
                                    type="text"
                                    name="last-name"
                                    id="last-name"
                                    v-model="formData.lastName"
                                    placeholder="Pseudonym last"
                            />
                            <span class="md-error" v-if="!$v.formData.lastName.required && showMdInvalidClass($v.formData.lastName, submitted)"><md-icon class="error-icon">error</md-icon>Last name is required</span>
                        </div>

                        <!-- EMAIL -->
                        <div class="form-group" :class="{'md-invalid': showMdInvalidClass($v.formData.email, submitted)}">
                            <div class="tooltipbox tooltipemail sublowtooltip" v-show="tooltipemail">Enroll any member of your team to instant alerts any time a patient connects with your program.</div>

                            <!--                                                            <div>-->
                            <label for="last-name">Private email <i @mouseover="tooltipemail = true" @mouseout="tooltipemail = false" class="md-icon md-icon-font md-theme-default emailquestion">help_outline</i></label>
                            <input
                                    @input="formChanged=true"
                                    type="email"
                                    name="email"
                                    id="email"
                                    v-model="formData.email"
                                    placeholder="Email"
                            />
                            <span class="md-error" v-if="!$v.formData.email.required && showMdInvalidClass($v.formData.email, submitted)"><md-icon class="error-icon">error</md-icon>Email is required</span>
                            <span class="md-error" v-else-if="!$v.formData.email.email && showMdInvalidClass($v.formData.email, submitted)"><md-icon class="error-icon">error</md-icon>The email address is invalid</span>
                        </div>

                        <!--PHONE -->
                        <!--                                                            <div :class="{'md-invalid': showMdInvalidClass($v.formData.phone, submitted)}">-->
                        <!--                                                                <label for="admissions-phone">Phone</label>-->
                        <!--                                                                <input-->
                        <!--                                                                        @change="formChanged=true"-->
                        <!--                                                                        type="tel"-->
                        <!--                                                                        name="admissions-phone"-->
                        <!--                                                                        id="admissions-phone"-->
                        <!--                                                                        v-model="formData.phone"-->
                        <!--                                                                        v-mask="'(###) ###-####'"-->
                        <!--                                                                        placeholder="Enter phone number"-->
                        <!--                                                                />-->
                        <!--                                                                <span class="md-error" v-if="!$v.formData.phone.required && showMdInvalidClass($v.formData.phone, submitted)"><md-icon class="error-icon">error</md-icon>Phone is required</span>-->
                        <!--                                                                <span class="md-error" v-else-if="!$v.formData.phone.min"><md-icon class="error-icon">error</md-icon>Phone is too short</span>-->
                        <!--                                                            </div>-->

                        <div class="stock-images">
                            <div class="tooltipbox tooltipbudget sublowtooltip" v-show="tooltipbudget">Rehab.com encourages your admission department to use pseudonym names to protect your identity.</div>
                            <p>Choose a photo <i @mouseover="tooltipbudget = true" @mouseout="tooltipbudget = false" class="md-icon md-icon-font md-theme-default">help_outline</i></p>
                            <div
                                    v-for="image in stockImages"
                                    class="stock-image"
                                    :class="{ 'selected-image': image === formData.presetPhoto }"
                                    :key="image"
                                    @click="formData.presetPhoto = image; formChanged = true"
                            >
                                <img :src="'https://rehab-connect.s3.amazonaws.com/admissions-agents/presets/' + image">
                            </div>
                        </div>
                        <div class="custom-photo-div">
                            <label for="custom-photo">
                                ...upload your own
                            </label>
                            <div @click="openPhotoClick" class="file-name-wrapper">
                              <md-button
                                  class="md-raised choose-file"
                              >Choose file</md-button>
                                <span>{{ imageName ? imageName : 'No file chosen' }}</span>
                            </div>
                            <input
                                    style="display:none;"
                                    type="file"
                                    @change="onFileSelected"
                                    accept="image/*"
                                    name="custom-photo"
                                    id="custom-photo"
                                    ref="customphoto"
                            >
                            <div v-if="imagePreview || currentCustomPhoto" class="image-wrapper form-preview-image selected-image stock-image">
                              <p>Preview:</p>
                              <img :src="imagePreview ? imagePreview : currentPhoto" class="image-preview" alt="preview"/>
                            </div>
                            <p
                                class="image-error"
                                v-if="imageError"
                            ><md-icon class="error-icon">error</md-icon> Must be a PNG or JPG image</p>
                        </div>
                    </div>
                </div>
            </md-card-content>
            <md-card-actions :class="{'actions' : !bulk , 'actions actionsBulk': bulk}">
                <md-button
                        v-if="bulk"
                        class="customGrey"
                        @click.prevent="$emit('cancel')"
                >Cancel</md-button>
                <md-button
                        class="md-raised md-primary"
                        type="submit"
                        :disabled="loading || !formChanged"
                >Save</md-button>
            </md-card-actions>
        </md-card>

        <md-snackbar
                md-position="center"
                :md-duration="Infinity"
                :md-active.sync="showSnackbar"
                md-persistent
        >
            <span>{{ snackMsg }}</span>
            <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
        </md-snackbar>

        <md-dialog :md-active.sync="showImageEditor" class="agent-image-dialog">
            <md-dialog-title>Profile image</md-dialog-title>
            <md-dialog-content>
                <cropper
                    class="upload-cropper"
                    :src="image"
                    :stencil-props="{aspectRatio: 1}"
                    @change="onCropChange"
                />

                <div class="image-wrapper">
                    <p>Preview:</p>
                    <img :src="imagePreview" class="image-preview" alt="preview"/>
                </div>

            </md-dialog-content>
            <md-dialog-actions>
              <md-button class="md-primary" @click="showImageEditor = false">Accept</md-button>
              </md-dialog-actions>
        </md-dialog>

    </form>
</template>

<script>
    import { validationMixin } from 'vuelidate'
    import {
        required, email
    } from 'vuelidate/lib/validators'
    // import {mapActions, mapGetters} from 'vuex'
    import customValidationMixin from '../../../mixins/validationMixin'
    import { Cropper } from 'vue-advanced-cropper'

    export default {
        name: "AdmissionsAgentForm",

        mixins: [validationMixin, customValidationMixin],

        components: {Cropper},

        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            originalFormData: {
                type: Object,
                default:function(){
                    return {
                        firstName: null,
                        lastName: null,
                        email: null,
                        // phone: null,
                        photo: null,
                        presetPhoto: null,
                        selectedFile: null,
                    }
                }
            },
            bulk: {
                type: Boolean,
                default: false
            },
            currentPhoto: {
                type: String,
                default: null
            }
        },

        data(){
            return {
                formChanged: false,
                formData: {
                    firstName: null,
                    lastName: null,
                    email: null,
                    // phone: null,
                    photo: null,
                    presetPhoto: null,
                    selectedFile: null,
                },
                origImage: null,
                image: null,
                imagePreview: null,
                imageName: null,
                mimeType: null,
                imageError: false,
                submitted: false,
                showImageEditor: false,
                tooltipbudget: false,
                tooltipemail: false,
                stockImages: [
                    "headshot1.jpg",
                    "headshot2.jpg",
                    "headshot3.jpg",
                    "headshot4.jpg",
                    "headshot5.jpg",
                    "headshot6.jpg",
                    "headshot7.jpg",
                    "headshot8.jpg",
                    "headshot9.jpg",
                    "headshot10.jpg",
                    "headshot11.jpg",
                    "headshot12.jpg",
                ],
                showSnackbar: false,
                snackMsg: "",
            }

        },

        computed: {
            imagePrefix() {
                if(window.location.origin === 'http://localhost:8080'){
                    return 'https://s3.amazonaws.com/rehab-test-upload/'
                }else {
                    return 'https://s3.amazonaws.com/rehab-connect/'
                }
            },
            currentCustomPhoto(){
                if(!this.currentPhoto){
                   return false
                }
                for(const stockImage of this.stockImages){
                    if(this.currentPhoto.includes(stockImage)){
                        return false;
                    }
                }
                return true;
            }
        },

        mounted() {
            this.formData = Object.assign(this.formData, JSON.parse(JSON.stringify(this.originalFormData)))

            if(this.currentPhoto){
                const matchingPhoto = this.stockImages.find(stock => this.currentPhoto.includes(stock));
                if(matchingPhoto){
                    this.formData.presetPhoto = matchingPhoto
                }
            }
        },

        validations: {
            formData: {
                firstName: {required},
                lastName: {required},
                email: {
                    required,
                    email
                },
                // phone: {
                //     required,
                //     min: minLength(14)
                // },
            },
        },

        methods: {
            openPhotoClick(){
              console.log('REFS', this.$refs);
              this.$refs.customphoto.click()
            },
            onFileSelected(event){
                // console.log(event);
                // this.formData.selectedFile = event.target.files[0];
                // this.formChanged = true;

                const files = event.target.files
                const image = files[0]
                this.origImage = files[0];
                this.mimeType = image.type;
                // console.log('IMAGE SIZE', this.formatBytes(image.size))
                if(!image.name.includes('jpg') && !image.name.includes('jpeg') && !image.name.includes('png') && !image.name.includes('gif')){
                  // console.error('invalid image type', image.name)
                    this.imageError = true;
                    return;
                }
                this.imageError = false;
                this.imageName = image.name

                const reader = new FileReader();
                reader.onload = (e) => {
                    // Read image as base64 and set to imageData
                    this.image = e.target.result;

                };
                // console.log('THE READER', reader);
                reader.readAsDataURL(files[0]);

                this.showImageEditor = true;
                this.formChanged = true;
            },

            cropImage(event) {
                const { canvas } = this.$refs.cropper.getResult();
                if (canvas) {
                    canvas.toBlob(blob => {
                        this.formData.selectedFile = new File([blob], this.value.name)
                        // this.information.image = blob;
                    }, this.mimeType)
                }
            },

            onCropChange({canvas}){
                if(canvas){
                    // console.log('THE CANVAS', canvas);
                    canvas.toBlob(blob => {
                        // console.log('the blob', blob)
                        this.imagePreview = URL.createObjectURL(blob);
                        this.formData.selectedFile = new File([blob], this.origImage.name)
                        // console.log('IMAGE PREVIEW SIZE', this.formatBytes(blob.size))
                        // console.log('IMAGE FILE SIZE', this.formatBytes(this.information.image.size))
                    }, this.mimeType)
                }
            },

            validateForm() {
                this.$v.formData.$touch()
                if(this.$v.formData.$error){
                    console.error('Error validating', this.$v.formData)
                    return false
                }

                if(!this.formData.selectedFile && !this.formData.presetPhoto){
                    this.snackMsg = 'Please select a photo or upload your own.'
                    this.showSnackbar = true;
                    return false
                }

                return true;
            },

            attemptSubmit() {
                this.submitted = true;

                if(!this.validateForm()){
                    return false;
                }

                this.$emit('submit', this.formData);


            }
        }
    }
</script>

<style lang="scss" scoped>
    .md-dialog {
       border-radius: 10px;
    }
    .agent-image-dialog {
      top: 270px;
    }

    .actions{
        width: 640px;
        padding-top: 24px;
        padding-bottom: 20px;
    }

    .md-layout-item img{
        height: 80px;
    }

    .icon{
        display: inline;
        .md-icon{
            color: #000!important;
            font-size: 20px!important;
        }
        :hover{
            cursor: pointer;
        }
    }

    .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
        box-shadow: none;
    }

    .md-error.padding{
        padding-left: 16px;
        margin-top: -16px;
    }

    .form-group {
        margin-bottom: 26px;
    }
    .md-invalid {
        margin-bottom: 0;
    }

    select, input{
        margin-left: 160px;
        cursor: pointer;
        outline: none;
        border: 1px solid #eee;
        height: 46px;
        border-radius: 5px;
        padding-left: 16px;
        -webkit-text-fill-color: unset !important;
        width: 456px;

        font-size: 15px;
        color: #43484f;
        padding-right: 26px;
        z-index: 9999 !important;

    }

    input{
        cursor: auto;
        -webkit-appearance: none !important;
    }

    .stock-images{
        max-width: 600px;
        position: relative;
        padding-left: 10px;
        min-height: 221px;
    }

    .stock-image{
        border: 2px solid #fff;
        max-width: 100px;
        max-height: 100px;
        display: inline-flex;
        padding: 4px;
        img{
            border-radius: 50%;
        }
    }
    .stock-image:hover, .selected-image{
        border: 2px solid #0b61c9;
    }

    .custom-photo-div {
        position: relative;
            margin-top: 25px;
            .image-error {
                color: #d93025 !important;
                display: block !important;
                position: absolute !important;
                top: 40px;
                font-size: 12px;
                left: 160px;
                .error-icon{
                    font-size: 16px!important;
                    color: #d93025!important;
                }
            }

        .form-preview-image {
            max-width: 92px;
            position: absolute;
            text-align: left;
            left: 16px;
            top: 30px;
                p {
                   display: none;
                }
        }
    }
    .stock-images{
        .md-icon{
            color: #c6c6c6 !important;
            font-size: 18px!important;
        }
    }
    .bulk-admin {
      .stock-images {
        padding-left: 0;
        .stock-image {
          img {
            height: 82px
          }
        }
      }
      .custom-photo-div {
        label {
          padding-left: 0;
        }
        #custom-photo {
          display: none;
        }

      }
    }


    .file-name-wrapper {
      cursor: pointer;
      background-color: #f0f0f0;
      height: 45px;
      margin-left: 160px;
      max-width: 425px;
      border: 1px solid #dedede;
      border-radius: 5px;
      .choose-file {
        box-shadow: none;
        background-color: #fff;
        height: 31px;
        font-weight: 300;
      }
      .choose-file:hover {
        background-color: #fff;
      }
      span {
        line-height: 45px;
        color: #9e9e9e;
        margin-left: 5px;
        font-weight: 300;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
      }
    }


    .tooltipbox{
        position: absolute;
        z-index: 6;
        background-color: #fff;
        width: 260px;
        height: 100px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
        padding: 10px;
        top:30px;
        left:120px;
    }
    .custom-photo-div input{
        padding-top: 21px;
    }

    #custom-photo{
        padding-top: 12px;

    }

    .emailquestion{
        position: absolute;
        color: #c6c6c6 !important;
        font-size: 18px !important;
        margin-left: 2px;
    }

    .md-icon:hover{
        cursor: pointer;
    }

    .tooltipemail{
        top: 160px;
    }
    
    .actionsBulk{
        margin-right: 0px;
        padding-right: 15px;
        padding-top: 30px;
        padding-bottom: 0px;
    }

    #custom-photo{
        margin-top: 6px;
        
    }

    .upload-cropper {
      border: solid 1px #EEE;
      height: 200px;
      width: 100%;
    }

    .image-wrapper {
      width: 100%;
      text-align: center;
      p {
        margin-top: 15px !important;
        margin-bottom: 5px !important;
      }
      .image-preview {
        max-width: 100px;
        max-height: 100px;
        border-radius: 50%;
      }
    }


</style>